import axios from "axios";

export interface PatchDesignParams {
  designId: string;
  tags: string[] | null;
}

axios.defaults.withCredentials = true;
axios.defaults.headers["Access-Control-Allow-Origin"] = "*";
axios.defaults.baseURL = "https://hash-tag.vplate.io";

export const getDesignList = async (page: number) => {
  try {
    const response = await axios.get("/", {
      params: {
        page,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const patchDesign = async ({ designId, tags }: PatchDesignParams) => {
  try {
    const response = await axios.patch(`/${designId}`, { tags });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAutoComplete = async (keyword: string) => {
  try {
    const response = await axios.get("/tags", {
      params: {
        search: keyword,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
