import React, { useCallback, useEffect } from 'react'
import { Modal as DaisyModal } from 'react-daisyui'

interface ModalProps {
  open?: boolean
  title?: string
  onClose: () => void
  children: React.ReactNode
  headerClassName?: string
  bodyClassName?: string
  wrapperClassName?: string
}

const Modal = (props: ModalProps) => {
  const {
    open = true,
    title,
    onClose,
    children,
    headerClassName,
    bodyClassName,
    wrapperClassName,
  } = props
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.body.style.overflow = 'hidden'
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.body.style.overflow = 'auto'
    }
  }, [handleKeyDown])

  return (
    <DaisyModal
      open={open}
      onClickBackdrop={onClose}
      className={wrapperClassName}
    >
      {title && (
        <DaisyModal.Header className={`font-bold ${headerClassName || ''}`}>
          {title}
        </DaisyModal.Header>
      )}
      <DaisyModal.Body className={`w-full ${bodyClassName || ''}`}>
        {children}
      </DaisyModal.Body>
    </DaisyModal>
  )
}

export default Modal
