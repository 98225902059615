import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "../components/Layout/AppLayout";
import HashTag from "./main";

const Router = () => {
  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<HashTag />} />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
};

export default Router;
