import { useEffect, useState } from 'react'
import { Toast as DaisyToast, Alert } from 'react-daisyui'

interface ToastProps {
  vertical?: 'top' | 'middle' | 'bottom'
  horizontal?: 'center' | 'start' | 'end'
  toastClassName?: string
  alertClassName?: string
  status: 'success' | 'error' | 'warning' | 'info'
  children: string | React.ReactNode
  setState: React.Dispatch<React.SetStateAction<boolean>>
}

const Toast = ({
  vertical,
  horizontal,
  toastClassName,
  alertClassName,
  status,
  children,
}: ToastProps) => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {show && (
        <DaisyToast
          vertical={vertical}
          horizontal={horizontal}
          className={toastClassName}
        >
          <Alert className={alertClassName} status={status}>
            {children}
          </Alert>
        </DaisyToast>
      )}
    </>
  )
}

export default Toast
