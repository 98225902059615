import { throttle } from 'lodash'
import { useInfiniteQuery, useQuery } from 'react-query'
import { getAutoComplete, getDesignList } from '../../services/designs'
import { DesignResponse, HashTagResponse } from './components/types'

export const useInfiniteDesingsQuery = (page: number) => {
  const { data, isFetching } = useInfiniteQuery<DesignResponse, boolean>(
    ['designs', page],
    async () => await getDesignList(page),
    {
      cacheTime: 30000,
      staleTime: 30000,
      getNextPageParam: (currentPage, allPages) => {
        if (currentPage?.data?.length <= 0) {
          return false
        }
        return allPages.length + 1
      },
    }
  )
  return {
    designs: data?.pages[0].data,
    isFetching,
    total: data?.pages[0].totalPages && data?.pages[0].totalPages,
  }
}

export const useHashTagQuery = throttle((hashtag: string) => {
  const { data } = useQuery<HashTagResponse>(
    ['designs', hashtag],
    async () => await getAutoComplete(hashtag),
    {
      enabled: !!hashtag,
    }
  )

  return data?.data?.Items
})
