import { useState, useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import Toast from '../../../components/Toast'
import { patchDesign } from '../../../services/designs'
import { useInfiniteDesingsQuery } from '../_queries'
import DesignPagination from './DesignPagination'
import DesignTable from './DesignTable'
import EditModal from './EditModal'
import { DesignData } from './types'

const DesignList = () => {
  const queryClient = useQueryClient()
  const [selectedDesign, setSelectedDesign] = useState<DesignData | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isError, setIsError] = useState(false)
  const [totalPage, setTotalPage] = useState(0)

  const { designs, isFetching, total } = useInfiniteDesingsQuery(currentPage)

  const onClick = useCallback((design: DesignData) => {
    setSelectedDesign(design)
  }, [])

  const handleEditDesign = useCallback(
    async (tags: string[]) => {
      if (!designs || !selectedDesign) return
      setIsError(false)
      const { _id } = selectedDesign

      const index = designs.findIndex((item) => item._id === _id)
      if (index > -1) {
        setSelectedDesign(null)

        const result = await patchDesign({ designId: _id, tags })
        if (!result) {
          setIsError(true)
          return
        }
        queryClient.setQueryData(['designs', currentPage], (oldData: any) => {
          const newData = [...oldData.pages]
          newData[0].data[index] = result.data
          return {
            ...oldData,
            pages: newData,
          }
        })
      }
    },
    [currentPage, designs, queryClient, selectedDesign]
  )

  useEffect(() => {
    if (!total) return
    setTotalPage(total)
  }, [total])

  return (
    <div className='overflow-x-auto'>
      {isError && (
        <Toast
          vertical='top'
          horizontal='center'
          toastClassName='w-max z-50'
          alertClassName='text-white'
          status='error'
          setState={setIsError}
        >
          디자인 정보 수정에 실패했습니다.
        </Toast>
      )}
      <DesignTable
        designs={designs}
        isFetching={isFetching}
        currentPage={currentPage}
        onClick={onClick}
      />
      <DesignPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPage={totalPage}
      />
      {selectedDesign && (
        <EditModal
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          handleEditDesign={handleEditDesign}
        />
      )}
    </div>
  )
}

export default DesignList
