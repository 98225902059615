import { Button, Table } from "react-daisyui";
import Loader from "../../../components/Loader";
import Tags from "../../../components/Tags";
import { DesignData } from "./types";
import { designListColumns } from "../constants";

interface DesignTableProps {
  designs?: DesignData[];
  isFetching: boolean;
  currentPage: number;
  onClick: (design: DesignData) => void;
}

const DesignTable = ({
  designs,
  isFetching,
  currentPage,
  onClick,
}: DesignTableProps) => {
  return (
    <Table className="w-full min-h-[1600px]">
      <Table.Head>
        {designListColumns.map(({ column, className }, idx) => {
          return (
            <div key={`th-${idx}`} className={className}>
              {column}
            </div>
          );
        })}
      </Table.Head>
      <Table.Body>
        {isFetching && (
          <Loader
            color="#e80d07"
            size={200}
            top={"50%"}
            left={"50%"}
            loaderClassName="-translate-x-1/2 -translate-y-1/2"
          />
        )}
        {designs?.map((item, index) => {
          if (!item) return null;
          const { _id, temTitle, tags } = item;
          return (
            <Table.Row key={`design-${_id}`}>
              <div>{(currentPage - 1) * 20 + index + 1}</div>
              <div>{temTitle}</div>
              <div className="max-w-3xl flex flex-wrap">
                {tags.length <= 0
                  ? ""
                  : tags.map((tag, tagIdx) => {
                      return (
                        <Tags
                          key={`design-tag-${tagIdx}`}
                          tag={tag}
                          index={tagIdx}
                          tagClassName="w-max"
                        />
                      );
                    })}
              </div>
              <Button className="animate-none" onClick={() => onClick(item)}>
                수정
              </Button>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default DesignTable;
