import { useEffect, useState } from 'react'
import useQueryDebounce from '../../../hooks/useQueryDebounce'
import useTagsInput from '../../../hooks/useTagsInput'
import { useHashTagQuery } from '../_queries'
import HashTagMenu from './HashTagMenu'

interface TagsInputProps {
  tags: string[]
  setTags: React.Dispatch<React.SetStateAction<string[]>>
}

const HashTagsInput = ({ tags, setTags }: TagsInputProps) => {
  const { inputValue, setInputValue, inputRef, renderTagsInput, checkError } =
    useTagsInput({
      tags,
      setTags,
      autoFocus: true,
    })
  const [selectItem, setSelectItem] = useState<string>('')
  // 해시태그 자동완성
  const debouncedValue = useQueryDebounce(inputValue, inputValue ? 1000 : 0)
  const hashtags = useHashTagQuery(debouncedValue)

  const handleSelectItem = async () => {
    const result = checkError(selectItem)
    if (result) return
    setTags([...tags, selectItem])
    setSelectItem('')
  }

  useEffect(() => {
    if (!selectItem) return
    handleSelectItem()
  }, [selectItem])

  useEffect(() => {
    setInputValue('')
  }, [selectItem])

  return (
    <>
      {renderTagsInput()}
      {hashtags && (
        <HashTagMenu
          hashtags={hashtags}
          current={inputRef.current}
          setSelectItem={setSelectItem}
        />
      )}
    </>
  )
}

export default HashTagsInput
