import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Menu as DaisyMenu } from 'react-daisyui'
import Portal from '../../utils/portal'

interface MenuProps {
  list: string[]
  emptyText: string
  menuClassName?: string
  setSelectItem: React.Dispatch<React.SetStateAction<string>>
}

const Menu = ({ list, emptyText, menuClassName, setSelectItem }: MenuProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const itemRef = useRef<null[] | HTMLLIElement[]>([])

  const moveToItem = useCallback((index: number) => {
    itemRef.current[index]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }, [])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.isComposing || list.length <= 0) return

      switch (e.code) {
        case 'Space':
          setSelectItem(list[activeIndex])
          break
        case 'ArrowDown':
          setActiveIndex((prev) => (prev + 1) % list.length)
          break
        case 'ArrowUp':
          setActiveIndex((prev) => (prev - 1 + list.length) % list.length)
          break
        default:
          break
      }
      moveToItem(activeIndex)
    },
    [list, setSelectItem, activeIndex]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <Portal>
      <div
        className={`
          fixed flex w-52 h-80 overflow-y-scroll justify-center items-center
          p-4 ${menuClassName} z-[10000000] bg-white
        `}
      >
        <DaisyMenu className='absolute top-0 w-full visible'>
          {list.length > 0 &&
            list.map((item, index) => {
              return (
                <DaisyMenu.Item
                  key={`menu-item-${index}`}
                  onClick={() => setSelectItem(item)}
                  className={`${activeIndex === index && 'bg-neutral-200'}`}
                  ref={(current) => (itemRef.current[index] = current)}
                >
                  <span className='p-2'>{item}</span>
                </DaisyMenu.Item>
              )
            })}
          {list.length <= 0 && <DaisyMenu.Item>{emptyText}</DaisyMenu.Item>}
        </DaisyMenu>
      </div>
    </Portal>
  )
}

export default Menu
