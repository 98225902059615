import { useState } from "react";
import { Button, Pagination } from "react-daisyui";
import { getButtonClassName } from "../constants";

interface DesignPaginationProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPage: number;
}

const DesignPagination = ({
  currentPage,
  setCurrentPage,
  totalPage,
}: DesignPaginationProps) => {
  const [page, setPage] = useState(1);
  const lastIndex = page * 10 - 1;
  const firstIndex = lastIndex - 9;

  const handlePrevButton = () => {
    setCurrentPage(firstIndex);
    setPage(page - 1);
  };

  const handleNextButton = () => {
    setCurrentPage(lastIndex + 2);
    setPage(page + 1);
  };

  return (
    <div className="w-full flex justify-center py-10">
      {firstIndex > 0 && (
        <Button
          onClick={handlePrevButton}
          animation={false}
          className="!rounded-r-none rounded-l-lg"
        >
          &#60;
        </Button>
      )}
      <Pagination>
        {Array(totalPage)
          .fill(0)
          .map((_, index) => {
            if (firstIndex <= index && index <= lastIndex) {
              return (
                <Button
                  key={`pagination-btn-${index}`}
                  active={currentPage === index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  animation={false}
                  className={getButtonClassName(
                    firstIndex,
                    lastIndex,
                    totalPage,
                    index
                  )}
                >
                  {index + 1}
                </Button>
              );
            }
            return <></>;
          })}
      </Pagination>
      {totalPage && lastIndex < totalPage && (
        <Button
          onClick={handleNextButton}
          animation={false}
          className="!rounded-l-none rounded-r-lg"
        >
          &#62;
        </Button>
      )}
    </div>
  );
};

export default DesignPagination;
