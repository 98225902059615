import { Navbar, Button, Dropdown } from "react-daisyui";
import { useNavigate } from "react-router-dom";
import { menuItems } from "../../pages/main/constants";

const AppLayout = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="pb-4 flex w-full component-preview p-4 items-center justify-center gap-2">
        <Navbar>
          <Navbar>
            <Dropdown>
              <Button color="ghost" shape="circle" tabIndex={0}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h7"
                  />
                </svg>
              </Button>
              <Dropdown.Menu tabIndex={0} className="menu-compact w-52">
                {menuItems.map(({ title, link }) => {
                  return (
                    <Dropdown.Item key={title} onClick={() => navigate(link)}>
                      {title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              color="ghost"
              className="normal-case text-xl"
              onClick={() => navigate("/")}
            >
              Vplate
            </Button>
          </Navbar>
        </Navbar>
      </div>
      <div className="w-full px-[5%] lg:px-[10%]">{children}</div>
    </>
  );
};

export default AppLayout;
