interface TagsProps {
  tag: string
  removeTags?: (index: number) => void
  index: number
  tagClassName?: string
}

const Tags = ({ tag, removeTags, index, tagClassName }: TagsProps) => {
  return (
    <li
      className={`flex justify-center items-center gap-2 h-8 text-white rounded bg-gray-600 px-2 mr-2 mb-2 ${
        tagClassName || ''
      }`}
    >
      <span className='text-xs'>{tag}</span>
      {removeTags && (
        <span
          className='block w-4 h-4 rounded-full bg-white text-gray-600 text-xs leading-4 text-center cursor-pointer'
          onClick={() => removeTags(index)}
        >
          x
        </span>
      )}
    </li>
  )
}

export default Tags
