import DesignList from './components/DesignList'
import { Helmet } from 'react-helmet'

const HashTag = () => {
  return (
    <>
      <Helmet>
        <title>Design | HashTag</title>
      </Helmet>
      <DesignList />
    </>
  )
}

export default HashTag
