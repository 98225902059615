import Menu from '../../../components/Menu'
import { Item } from './types'

interface HashTagMenuProps {
  hashtags: Item[]
  current: HTMLInputElement | null
  setSelectItem: React.Dispatch<React.SetStateAction<string>>
}

const HashTagMenu = ({
  hashtags,
  current,
  setSelectItem,
}: HashTagMenuProps) => {
  const offset = current?.getBoundingClientRect()

  return (
    <Menu
      list={hashtags.map((hashtag) => hashtag.tagName)}
      emptyText='해당 해시태그가 없습니다.'
      menuClassName={`left-[${Math.round(
        offset?.left || 0
      )}px] top-[${Math.round(offset?.bottom || 0)}px]`}
      setSelectItem={setSelectItem}
    />
  )
}

export default HashTagMenu
