import { useState, useEffect, useCallback, useRef } from 'react'
import { Input, Toast } from 'react-daisyui'
import { IconAlertRed } from '../assets/svg'
import Tags from '../components/Tags'

interface TagsInputProps {
  tags: string[]
  setTags: React.Dispatch<React.SetStateAction<string[]>>
  autoFocus?: boolean
  freeAdd?: boolean
}

const useTagsInput = ({
  tags,
  setTags,
  autoFocus,
  freeAdd,
}: TagsInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [isError, setIsError] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const checkError = (value: string) => {
    if (tags.includes(value)) {
      setIsError(true)
      return true
    }
    setIsError(false)
    return
  }

  const addTags = () => {
    const result = checkError(inputValue)
    if (!inputValue || result) return
    setTags([...tags, inputValue])
    setInputValue('')
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!freeAdd) return
    if (e.code === 'Space') {
      addTags()
    }
  }

  const removeTags = useCallback(
    (indexToRemove: number) => {
      setTags([...tags.filter((_, index) => index !== indexToRemove)])
    },
    [setTags, tags]
  )

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setInputValue(value.trim())
  }

  const handleFocus = () => {
    inputRef.current && inputRef.current.focus()
  }

  useEffect(() => {
    autoFocus && handleFocus()
  }, [autoFocus])

  const renderTagsInput = () => (
    <>
      {isError && (
        <Toast
          vertical='top'
          horizontal='end'
          className='rounded-lg text-red-600 text-sm p-2 mt-2 mr-2 '
        >
          <div className='flex items-center gap-1'>
            <IconAlertRed />
            이미 추가되어 있는 태그입니다.
          </div>
        </Toast>
      )}
      <div
        className='flex items-start flex-wrap min-h-[48px] px-2 border-solid border-[1px] border-neutral-300 rounded-lg'
        onClick={handleFocus}
      >
        <ul className='flex flex-wrap p-0 mt-2'>
          {tags.map((tag, index) => (
            <Tags
              key={`tag-${index}`}
              tag={tag}
              removeTags={removeTags}
              index={index}
            />
          ))}
        </ul>
        <Input
          ref={inputRef}
          className='border-none px-1 focus:outline-none'
          placeholder='Press space to add tags'
          value={inputValue}
          onChange={onChangeValue}
          onKeyUp={handleKeyUp}
        />
      </div>
    </>
  )

  return {
    inputValue,
    setInputValue,
    inputRef,
    isError,
    setIsError,
    renderTagsInput,
    checkError,
  }
}

export default useTagsInput
