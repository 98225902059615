export const menuItems = [
  {
    title: "HashTag",
    link: "/hashtag",
  },
];

export const designListColumns = [
  { column: "num", className: "w-16" },
  { column: "temTitle", className: "w-44" },
  { column: "tags", className: "w-72" },
  { column: "edit", className: "w-20" },
];

export const designColumns = [
  {
    column: "영상",
    key: "video",
  },
  {
    column: "제목",
    key: "temTitle",
  },
  {
    column: "태그",
    key: "tags",
  },
];

export const getButtonClassName = (
  firstIndex: number,
  lastIndex: number,
  totalPage: number | undefined,
  index: number
) => {
  if (!totalPage) return "";
  if (
    (firstIndex > 1 && firstIndex === index) ||
    (lastIndex === index && lastIndex < totalPage - 1)
  ) {
    return "!rounded-none";
  }
  return "";
};
