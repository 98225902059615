import { useState, useRef, useEffect } from 'react'
import { Button, Table } from 'react-daisyui'
import Modal from '../../../components/Modal'
import HashTagsInput from './HashTagsInput'
import { DesignData } from './types'

type EditModalProps = {
  selectedDesign: DesignData
  setSelectedDesign: React.Dispatch<React.SetStateAction<DesignData | null>>
  handleEditDesign: (tags: string[]) => void
}

const EditModal = ({
  selectedDesign,
  setSelectedDesign,
  handleEditDesign,
}: EditModalProps) => {
  const onClose = () => setSelectedDesign(null)
  const [tags, setTags] = useState<string[]>([...selectedDesign.tags])
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
      videoRef?.current && videoRef.current.pause()
    }
  }, [])

  return (
    <Modal
      title='디자인 정보 수정'
      onClose={onClose}
      wrapperClassName='relative'
    >
      <form onSubmit={() => handleEditDesign(tags)}>
        <Table className='static'>
          <Table.Row>
            <span className='font-semibold'>영상</span>
            <div className='w-96'>
              <video
                playsInline
                autoPlay
                src={selectedDesign.temPreviewVideo}
                controls
                ref={videoRef}
                className='w-full'
              />
            </div>
          </Table.Row>
          <Table.Row>
            <span className='font-semibold'>제목</span>
            <div>{selectedDesign.temTitle}</div>
          </Table.Row>
          <Table.Row>
            <span className='font-semibold'>태그</span>
            <HashTagsInput tags={tags} setTags={setTags} />
          </Table.Row>
        </Table>
        <Button color='primary' fullWidth className='my-4 text-white'>
          수정하기
        </Button>
      </form>
    </Modal>
  )
}

export default EditModal
